import { type RouteRecordRaw } from 'vue-router';

import { type AccessMeta } from '@/services/auth/base-auth-service';

import { ROUTES } from './helpers/routes';

const Tracking: Array<RouteRecordRaw> = [
  {
    path: ROUTES.CONSIGNMENT_LIST.path,
    name: ROUTES.CONSIGNMENT_LIST.name,
    component: () => import('@/views/consignments/ConsignmentOverviewPage.vue'),
    props: true,
    meta: {
      title: ROUTES.CONSIGNMENT_LIST.title,
      betaMode: true,
    },
  },
  {
    path: ROUTES.CONSIGNMENT_ORDER_REQUEST.path,
    name: ROUTES.CONSIGNMENT_ORDER_REQUEST.name,
    component: () => import('@/views/consignments/ConsignmentOrderRequest.vue'),
    props: true,
    meta: {
      title: ROUTES.CONSIGNMENT_ORDER_REQUEST.title,
      allowAnonymous: true,
    },
  },
  {
    path: ROUTES.CONSIGNMENT_ORDER_REQUEST.path,
    name: ROUTES.CONSIGNMENT_ORDER_REQUEST.name,
    component: () => import('@/views/consignments/ConsignmentOrderRequest.vue'),
    props: true,
    meta: {
      title: ROUTES.CONSIGNMENT_ORDER_REQUEST.title,
      allowAnonymous: true,
      testMode: true,
    },
  },
  {
    path: ROUTES.CONSIGNMENT_LIST_TEST.path,
    name: ROUTES.CONSIGNMENT_LIST_TEST.name,
    component: () => import('@/views/consignments/ConsignmentOverviewPage.vue'),
    props: true,
    meta: {
      title: ROUTES.CONSIGNMENT_LIST_TEST.title,
      testMode: true,
      betaMode: true,
    },
  },
  {
    path: ROUTES.CONSIGNMENT_CREATE.path,
    name: ROUTES.CONSIGNMENT_CREATE.name,
    component: () => import('@/views/consignments/ConsignmentCreatePage.vue'),
    props: {
      mode: 'create',
    },
    meta: {
      title: ROUTES.CONSIGNMENT_CREATE.title,
      parents: { 0: ROUTES.CONSIGNMENT_LIST },
      betaMode: true,
    },
  },
  {
    path: ROUTES.CONSIGNMENT_CREATE_TEST.path,
    name: ROUTES.CONSIGNMENT_CREATE_TEST.name,
    component: () => import('@/views/consignments/ConsignmentCreatePage.vue'),
    props: {
      mode: 'create',
    },
    meta: {
      title: ROUTES.CONSIGNMENT_CREATE_TEST.title,
      parents: { 0: ROUTES.CONSIGNMENT_LIST_TEST },
      testMode: true,
      betaMode: true,
    },
  },
  {
    path: ROUTES.CONSIGNMENT_EDIT_TEST.path,
    name: ROUTES.CONSIGNMENT_EDIT_TEST.name,
    component: () => import('@/views/consignments/ConsignmentCreatePage.vue'),
    props: {
      mode: 'edit',
    },
    meta: {
      title: ROUTES.CONSIGNMENT_EDIT_TEST.title,
      testMode: true,
      access: {
        url: 'consignments/:id/edit',
        method: 'GET',
        service: 'shipping',
        version: 'v2',
      } as AccessMeta,
    },
  },
  {
    path: ROUTES.CONSIGNMENT_EDIT.path,
    name: ROUTES.CONSIGNMENT_EDIT.name,
    component: () => import('@/views/consignments/ConsignmentCreatePage.vue'),
    props: {
      mode: 'edit',
    },
    meta: {
      title: ROUTES.CONSIGNMENT_EDIT.title,
      parents: { 0: ROUTES.CONSIGNMENT_LIST },
      access: {
        url: ROUTES.CONSIGNMENT_EDIT.path,
        method: 'GET',
        service: 'shipping',
        version: 'v3',
      } as AccessMeta,
    },
  },
  {
    path: ROUTES.CONSIGNMENT_DETAIL.path,
    name: ROUTES.CONSIGNMENT_DETAIL.name,
    component: () => import('@/views/consignments/ConsignmentDetailPage.vue'),
    props: true,
    meta: {
      title: ROUTES.CONSIGNMENT_DETAIL.title,
      parents: { 0: ROUTES.CONSIGNMENT_LIST },
      betaMode: true,
      access: {
        url: ROUTES.CONSIGNMENT_DETAIL.path,
        method: 'GET',
        service: 'shipping',
        version: 'v1',
      } as AccessMeta,
    },
  },
  {
    path: ROUTES.CONSIGNMENT_DETAIL_TEST.path,
    name: ROUTES.CONSIGNMENT_DETAIL_TEST.name,
    component: () => import('@/views/consignments/ConsignmentDetailPage.vue'),
    props: true,
    meta: {
      title: ROUTES.CONSIGNMENT_DETAIL_TEST.title,
      parents: { 0: ROUTES.CONSIGNMENT_LIST_TEST },
      betaMode: true,
      testMode: true,
      access: {
        url: 'consignments/:id',
        method: 'GET',
        service: 'shipping',
        version: 'v1',
      } as AccessMeta,
    },
  },
  {
    path: ROUTES.CONSIGNMENT_TRACKING_LIST.path,
    name: ROUTES.CONSIGNMENT_TRACKING_LIST.name,
    component: () => import('@/views/tracking/3m-tracking.vue'),
    props: (route) => ({ signedUrl: route.query.signedUrl as string }),
    meta: {
      title: ROUTES.CONSIGNMENT_TRACKING_LIST.title,
      parents: { 0: ROUTES.CONSIGNMENT_LIST },
      allowAnonymous: true,
    },
  },
  {
    path: ROUTES.CONSIGNMENT_TRACKING_DETAIL.path,
    name: ROUTES.CONSIGNMENT_TRACKING_DETAIL.name,
    component: () => import('@/views/tracking/3m-tracking.vue'),
    props: true,
    meta: {
      title: 'Tracking',
      mode: 'production',
    },
  },
  {
    path: ROUTES.CONSIGNMENT_TRACKING_DETAIL_ACCEPTANCE.path,
    name: ROUTES.CONSIGNMENT_TRACKING_DETAIL_ACCEPTANCE.name,
    component: () => import('@/views/tracking/3m-tracking.vue'),
    props: true,
    meta: {
      title: ROUTES.CONSIGNMENT_TRACKING_DETAIL_ACCEPTANCE.title,
      parents: { 0: ROUTES.CONSIGNMENT_LIST, 1: ROUTES.CONSIGNMENT_TRACKING_LIST },
      mode: 'acceptance',
    },
  },
  {
    path: ROUTES.CONSIGNMENT_URLSIGNER.path,
    name: ROUTES.CONSIGNMENT_URLSIGNER.name,
    component: () => import('@/views/tracking/3m-tracking-overview.vue'),
    props: true,
    meta: {
      title: ROUTES.CONSIGNMENT_URLSIGNER.title,
      parents: { 0: ROUTES.CONSIGNMENT_LIST },
    },
  },
  {
    path: ROUTES.CONSIGNMENT_URLSIGNER.path,
    name: ROUTES.CONSIGNMENT_URLSIGNER.name,
    component: () => import('@/views/tracking/3m-tracking-overview.vue'),
    props: true,
    meta: {
      title: ROUTES.CONSIGNMENT_URLSIGNER.title,
      parents: { 0: ROUTES.CONSIGNMENT_LIST },
    },
  },
  {
    path: ROUTES.CONSIGNMENT_ORDER_REQUEST_DETAIL.path,
    name: ROUTES.CONSIGNMENT_ORDER_REQUEST_DETAIL.name,
    component: () => import('@/views/consignments/ConsignmentOrderRequest.vue'),
    props: true,
    meta: {
      title: ROUTES.CONSIGNMENT_ORDER_REQUEST_DETAIL.title,
      parents: { 0: ROUTES.CONSIGNMENT_LIST },
    },
  },
  {
    path: ROUTES.CONSIGNMENT_ORDER_REQUEST.path,
    name: ROUTES.CONSIGNMENT_ORDER_REQUEST.name,
    component: () => import('@/views/consignments/ConsignmentOrderRequest.vue'),
    props: true,
    meta: {
      title: ROUTES.CONSIGNMENT_ORDER_REQUEST.title,
      parents: { 0: ROUTES.CONSIGNMENT_LIST },
    },
  },
];

export default Tracking;
