import { defineStore } from 'pinia';

import { type components as configs } from '@/generated/configs';
import { AccessKey } from '@/plugins/authorization';
import { AuthService, UserInfo } from '@/services/auth/auth-service';
import { UserRoles } from '@/services/auth/opa-service';
import { OpaService } from '@/services/auth/opa-service';
import { UserProfile } from '@/services/configs/configs-types';
import { UserProfileService } from '@/services/configs/user-profile-service';
import { sendTrackingEvent } from '@/utils/logger';

type Printer = configs['schemas']['PrinterDetails'];

const userProfileService = new UserProfileService();
const authService = new AuthService();
const opaService = new OpaService();

export interface iAccess extends Record<AccessKey, boolean> {}

export const useUserStore = defineStore('users', {
  state: () => {
    return {
      info: undefined as UserInfo | undefined,
      profile: undefined as UserProfile | undefined,
      access: {} as iAccess,
      error: '',
      roles: {
        result: {},
      } as UserRoles,
      expiryWindows: ['3 Months', '6 Months', '1 Year'],
    };
  },
  getters: {
    isAuthenticated: (state): boolean => state.info !== undefined,
    getAccess: (state): iAccess => state.access,
  },
  actions: {
    async tryGetUserInfo(): Promise<boolean> {
      if (import.meta.env.PROD) {
        try {
          this.info = await getUserInfo();
          return true;
        } catch {
          return false;
        }
      }

      // development
      this.info = localDevUser;
      return true;
    },
    async tryGetUserProfile(): Promise<boolean> {
      try {
        this.profile = await getUserProfile();
        return true;
      } catch {
        return false;
      }
    },
    async setProfile(profile: UserProfile): Promise<boolean> {
      const response = await userProfileService.updateSingle(profile);
      if (response.isSuccess()) this.profile = profile;
      else console.warn('Failed to save user profile');

      return response.isSuccess();
    },
    async toggleFavoriteAddress(addressId: string): Promise<boolean> {
      if (this.profile === undefined) {
        return false;
      }

      // Toggle the favorite state
      const favoriteState = this.profile.favoriteAddresses.includes(addressId);
      if (favoriteState) {
        this.profile.favoriteAddresses = this.profile.favoriteAddresses.filter(
          (item) => item !== addressId
        );
        sendTrackingEvent('addresses.item.toggled_favorite', { value: false }); //  unknown source, address list or address select
      } else {
        this.profile.favoriteAddresses.push(addressId);
        sendTrackingEvent('addresses.item.toggled_favorite', { value: true });
      }
      await this.setProfile(this.profile);

      return true;
    },
    async setDefaultPrinter(key: string, printer: Printer): Promise<boolean> {
      if (this.profile === undefined) {
        return false;
      }

      if (!this.profile.defaultPrinters) {
        this.profile.defaultPrinters = {};
      }

      this.profile.defaultPrinters[key] = printer;
      await this.setProfile(this.profile);

      return true;
    },
    async getAvailableRoles() {
      const response = await opaService.getRoles();

      if (!response.isSuccess()) {
        console.error(response.problem);
        this.error = 'Failed to get available roles.';
        return;
      }
      this.roles = response.data;
    },

    updateAccess(key: AccessKey, value: boolean) {
      this.access[key] = value;
    },
    updateMultipleAccess(data: Partial<iAccess>) {
      this.access = { ...this.access, ...data };
    },
  },
});

const localDevUser: UserInfo = {
  identity: {
    id: 'local-dev',
    traits: {
      name: 'local-dev',
      email: 'dev@localhost',
    },
  },
};

async function getUserInfo(): Promise<UserInfo> {
  const response = await authService.getUserInfo();
  if (!response.isSuccess())
    throw new Error('Something went wrong while trying to authenticate you.');
  return response.data;
}

async function getUserProfile(): Promise<UserProfile> {
  const response = await userProfileService.readSingle();
  if (!response.isSuccess())
    throw new Error('Something went wrong while trying grab user profile.');
  return response.data;
}
