import mixpanel from 'mixpanel-browser';

import { usePostHog } from '@/composables/usePosthog';

const { posthog } = usePostHog();

export const sendTrackingEvent = (event: string, data?: object) => {
  // console.log(event, data); // For testing, comment posthog.capture
  posthog.capture(event, data);
  mixpanel.track(event, data);
};
