import { type App } from 'vue';

import { AuthService } from '@/services/auth/auth-service';
import { AuthResult } from '@/services/auth/base-auth-service';
import { TokenService } from '@/services/configs/token-service';
import { ShipmentService } from '@/services/shipping/shipment-service';

type iAccess = Record<AccessKey, boolean>;

declare module 'vue' {
  interface ComponentCustomProperties {
    $auth: (key: AccessKey) => Promise<boolean>;
    $auths: (key: AccessKey[]) => Promise<iAccess>;
  }
}

export enum AccessKey {
  CREATE_SHIPMENT,
  VIEW_SHIPMENTS,
  UPDATE_SHIPMENT,
  BOOK_SHIPMENT,

  EDIT_CONFIG,

  VIEW_TOKENS,
  VIEW_TOKEN,
  CREATE_TOKEN,
  DELETE_TOKEN,

  VIEW_INTERNAL,
}

const access: Record<AccessKey, () => Promise<AuthResult>> = {
  [AccessKey.CREATE_SHIPMENT]: ShipmentService.canCreateSingle,
  [AccessKey.VIEW_SHIPMENTS]: ShipmentService.canReadListWithQuery,
  [AccessKey.UPDATE_SHIPMENT]: ShipmentService.canUpdateSingle,
  [AccessKey.BOOK_SHIPMENT]: ShipmentService.canOrderSingle,

  [AccessKey.EDIT_CONFIG]: AuthService.canPostConfigs,

  [AccessKey.VIEW_TOKENS]: TokenService.canReadListWithQuery,
  [AccessKey.VIEW_TOKEN]: TokenService.canReadSingle,
  [AccessKey.CREATE_TOKEN]: TokenService.canCreateSingle,
  [AccessKey.DELETE_TOKEN]: TokenService.canDeleteSingle,

  [AccessKey.VIEW_INTERNAL]: AuthService.canGetInternal,
};

export default {
  install: (app: App): void => {
    app.config.globalProperties.$auth = async (key: AccessKey): Promise<boolean> =>
      (await access[key]()).authorized;

    app.config.globalProperties.$auths = async (keys: AccessKey[]): Promise<iAccess> => {
      const hasAccess: iAccess = {
        [AccessKey.CREATE_SHIPMENT]: false,
        [AccessKey.VIEW_SHIPMENTS]: false,
        [AccessKey.UPDATE_SHIPMENT]: false,
        [AccessKey.BOOK_SHIPMENT]: false,

        [AccessKey.EDIT_CONFIG]: false,

        [AccessKey.VIEW_TOKENS]: false,
        [AccessKey.VIEW_TOKEN]: false,
        [AccessKey.CREATE_TOKEN]: false,
        [AccessKey.DELETE_TOKEN]: false,

        [AccessKey.VIEW_INTERNAL]: false,
      };

      const permissions = Promise.all(
        keys.map(async (key) => {
          try {
            hasAccess[key] = (await access[key]()).authorized;
          } catch (error) {
            console.error(error);
            return;
          }
        })
      );

      return permissions.then(() => {
        return hasAccess;
      });
    };
  },
};
