import { BaseService, DataOrProblem, Options } from '@/services/base-service';
import { ApiClient } from '@/services/clients';
import {
  CancelShipmentResponse,
  CreateUpdateShipmentResponse,
  GetShipmentListQuery,
  Label,
  NoContentResult,
  OrderRequest,
  OrderShipmentResponse,
  Shipment,
  ShipmentListItemsWrapper,
  TrackingLinkResponse,
  TypedDocument,
} from '@/services/shipping/shipping-types';

const parentPath = '/shipping/v3/shipments';
const elementPath = `${parentPath}/{shipmentId}`;

// redoc at https://stpviyacontracts.z6.web.core.windows.net/?swagger=%252Fshipping%252Fv3%252Fswagger.json#tag/Shipment
export class ShipmentService extends BaseService {
  public static canCreateSingle = () => BaseService.authPost(parentPath);
  public async createSingle(
    shipment: Shipment,
    Playground: boolean,
    ...args: Options<CreateUpdateShipmentResponse>
  ): Promise<DataOrProblem<CreateUpdateShipmentResponse>> {
    return await this.executeRequest<CreateUpdateShipmentResponse>(
      async () =>
        await ApiClient.POST(parentPath, {
          params: { query: { Playground } },
          body: shipment,
        }),
      ...args
    );
  }

  public async readSingle(
    shipmentId: string,
    Playground: boolean,
    ...args: Options<Shipment>
  ): Promise<DataOrProblem<Shipment>> {
    return await this.executeRequest<Shipment>(
      async () =>
        await ApiClient.GET(elementPath, {
          params: {
            path: { shipmentId },
            query: { Playground },
          },
        }),
      ...args
    );
  }

  public static canReadListWithQuery = () => BaseService.authGet(parentPath);
  public async readListWithQuery(
    query: GetShipmentListQuery,
    Playground: boolean = false,
    ...args: Options<ShipmentListItemsWrapper>
  ): Promise<DataOrProblem<ShipmentListItemsWrapper>> {
    return await this.executeRequest<ShipmentListItemsWrapper>(
      async () =>
        await ApiClient.GET(parentPath, {
          params: {
            query: { Playground, ...query },
          },
        }),
      ...args
    );
  }

  public static canUpdateSingle = () => BaseService.authPut(elementPath);
  public async updateSingle(
    shipmentId: string,
    shipment: Shipment,
    Playground: boolean,
    ...args: Options<CreateUpdateShipmentResponse>
  ): Promise<DataOrProblem<CreateUpdateShipmentResponse>> {
    return await this.executeRequest<CreateUpdateShipmentResponse>(
      async () =>
        await ApiClient.PUT(elementPath, {
          params: {
            path: { shipmentId },
            query: { Playground },
          },
          body: shipment,
        }),
      ...args
    );
  }

  public async cancelSingle(
    shipmentId: string,
    Playground: boolean,
    ...args: Options<CancelShipmentResponse>
  ): Promise<DataOrProblem<CancelShipmentResponse>> {
    return await this.executeRequest<CancelShipmentResponse>(
      async () =>
        await ApiClient.PUT(`${elementPath}/cancel`, {
          params: {
            path: { shipmentId },
            query: { Playground },
          },
        }),
      ...args
    );
  }

  public async resetSingle(
    shipmentId: string,
    Playground: boolean,
    ...args: Options<NoContentResult>
  ): Promise<DataOrProblem<NoContentResult>> {
    return await this.executeRequest<NoContentResult>(
      async () =>
        await ApiClient.PUT(`${elementPath}/reset`, {
          params: {
            path: { shipmentId },
            query: { Playground },
          },
        }),
      ...args
    );
  }

  public async generateCmr(
    shipmentId: string,
    Playground: boolean,
    ...args: Options<TypedDocument>
  ): Promise<DataOrProblem<TypedDocument>> {
    return await this.executeRequest<TypedDocument>(
      async () =>
        await ApiClient.GET(`${elementPath}/cmr`, {
          params: {
            path: { shipmentId },
            query: { Playground },
          },
        }),
      ...args
    );
  }

  public static canOrderSingle = () => BaseService.authPut(`${elementPath}/order`);
  public async orderSingle(
    shipmentId: string,
    Playground: boolean,
    ...args: Options<OrderShipmentResponse>
  ): Promise<DataOrProblem<OrderShipmentResponse>> {
    return await this.executeRequest<OrderShipmentResponse>(
      async () =>
        await ApiClient.PUT(`${elementPath}/order`, {
          params: {
            path: { shipmentId },
            query: { Playground },
          },
        }),
      ...args
    );
  }

  public async orderRequest(
    shipmentId: string,
    body: OrderRequest,
    Playground: boolean,
    ...args: Options<Shipment>
  ): Promise<DataOrProblem<Shipment>> {
    return await this.executeRequest<Shipment>(
      async () =>
        await ApiClient.PUT(`${elementPath}/order-request`, {
          params: {
            path: { shipmentId },
            query: { Playground },
          },
          body,
        }),
      ...args
    );
  }

  public async retrieveTrackingLinks(
    shipmentId: string,
    Playground: boolean,
    ...args: Options<TrackingLinkResponse>
  ): Promise<DataOrProblem<TrackingLinkResponse>> {
    return await this.executeRequest<TrackingLinkResponse>(
      async () =>
        await ApiClient.PUT(`${elementPath}/retrieve-tracking-links`, {
          params: {
            path: { shipmentId },
            query: { Playground },
          },
        }),
      ...args
    );
  }

  public async generateShareableLink(
    shipmentId: string,
    Playground: boolean,
    ...args: Options<string>
  ): Promise<DataOrProblem<string>> {
    return await this.executeRequest<string>(
      async () =>
        await ApiClient.GET(`${elementPath}/shareable-link`, {
          params: {
            path: { shipmentId },
            query: { Playground, type: 'tracking' },
          },
        }),
      ...args
    );
  }

  public async uploadDocument(
    shipmentId: string,
    document: TypedDocument,
    Playground: boolean,
    ...args: Options<string>
  ): Promise<DataOrProblem<string>> {
    return await this.executeRequest<string>(
      async () =>
        await ApiClient.POST(`${elementPath}/documents`, {
          params: {
            path: { shipmentId },
            query: { Playground },
          },
          body: document,
        }),
      ...args
    );
  }

  public async getAllLabelsForShipment(
    shipmentId: string,
    Playground: boolean,
    ...args: Options<Label>
  ): Promise<DataOrProblem<Label>> {
    return await this.executeRequest<Label>(
      async () =>
        await ApiClient.GET(`${elementPath}/labels/merged`, {
          params: {
            path: { shipmentId },
            query: { Playground },
          },
        }),
      ...args
    );
  }
}
