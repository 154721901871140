import posthog from 'posthog-js';

const isLocalEnv = window.location.hostname === 'localhost';
const MODE = ['localhost', 'pr-', 'present', 'e2e'].some((str) =>
  window.location.href.includes(str)
)
  ? 'development'
  : 'production';

const PH_PROJECT_ID = {
  development: 'phc_40TkZIitthwtmQuwzygveYpIs3mOz6OcRkQG4TuUf8M',
  production: 'phc_r3WUJLR2b9x1i0Dpkw8cXQx7kURnbj5BJLS23XeBw9g',
};
const PH_CONFIG = {
  development: {
    api_host: isLocalEnv ? 'https://eu.i.posthog.com' : '/phproxy',
    autocapture: false,
    loaded: function (ph) {
      ph.opt_in_capturing(); // opts a user out of event capture
      ph.set_config({ disable_session_recording: true });
    },
  },
  production: {
    api_host: '/phproxy',
    ui_host: '/phproxy',
    autocapture: true,
  },
};

export function usePostHog() {
  posthog.init(PH_PROJECT_ID[MODE], {
    ...PH_CONFIG[MODE],
    capture_pageview: false,
  });

  return {
    posthog,
  };
}
