<template>
  <BaseDropDownMenu>
    <template #trigger>
      <span class="relative">
        <ButtonComponent
          @click="sendTrackingEvent('navigation.item.clicked', { route: 'release_notes' })"
          variant="ghost"
          icon="PhRocketLaunch"
          :data-testid="baseTestId + '.releases.trigger'"
        ></ButtonComponent>
        <span
          class="absolute bottom-1 right-1 rounded-full bg-blue-500 p-1 text-xs font-bold text-white"
        ></span>
      </span>
    </template>
    <template #content>
      <div class="grid max-w-sm gap-4">
        <div v-for="release in releases" :key="release.data.title">
          <DropdownMenuItem>
            <a :href="baseUrl + '/releases/' + release.slug" target="_blank">
              <div class="grid gap-1 p-2">
                <div class="pb-2 text-lg font-semibold">{{ release.data.title }}</div>
                <div class="pb-4">{{ release.data.description }}</div>
                <img :src="baseUrl + release.data.socialImage.src" alt="release note image" />
              </div>
            </a>
          </DropdownMenuItem>
        </div>
      </div>
    </template>
  </BaseDropDownMenu>
</template>
<script setup lang="ts">
import { ButtonComponent } from '@shipitsmarter/viya-ui-warehouse';
import { onMounted, ref } from 'vue';

import { DropdownMenuItem } from '@/components/ui/dropdown-menu';
import BaseDropDownMenu from '@/components/utilities/BaseDropDownMenu.vue';
import { CoreProblemDetails } from '@/services/clients';
import { ReleaseItem } from '@/services/untyped/untyped-types';
import { ViyaMeService } from '@/services/untyped/viya-me-service';
import { sendTrackingEvent } from '@/utils/logger';

const viyaMeService = new ViyaMeService();
const baseUrl = 'https://viya.me';

//TODO: This should be replaced by the API call from the CMS, but got a CORS issue
const releases = ref<ReleaseItem[]>([
  {
    id: 'september-2024.mdx',
    slug: 'september-2024',
    body: '\n{ frontmatter.description }\n\n![alt text](september-2024.png)\n',
    collection: 'releases',
    data: {
      title: 'A new look!',
      description:
        " Today we're launching our new design, helping you navigate our site more easily. We've also added a new feature that allows you to provide feedback on our content. We hope you enjoy the new look! ",
      feedback:
        'We hope you enjoy the new look! If you have any feedback or run into any issues, please let us know. ',
      publishDate: '2024-09-01T00:00:00.000Z',
      socialImage: {
        src: '/_astro/september-2024.BtMGMn4M.png',
        width: 2559,
        height: 1117,
        format: 'png',
        fsPath: '/app/src/content/releases/september-2024.png',
      },
    },
  },
  {
    id: 'tracking-link.mdx',
    slug: 'tracking-link',
    body: "\n### What's new\n\n{ frontmatter.description }\n\n![alt text](tracking-link.png)\n\n---\n\n### Want to start using the tracking link?\n\nFollow these steps:\n\n1. Go to the carrier configuration\n2. Select your Carrier Profile\n\n![explaination](tracking-link-config.png)\n\n3. Enable the tracking link\n4. Optionally add a custom tracking link\n",
    collection: 'releases',
    data: {
      title: 'Tracking Link',
      description:
        'A quick way to track your shipments, now available in the carrier configuration.',
      feedback: 'Let us know what you think about the new tracking link feature!',
      publishDate: '2024-10-14T00:00:00.000Z',
      socialImage: {
        src: '/_astro/tracking-link.CNxVPHEe.png',
        width: 1215,
        height: 459,
        format: 'png',
        fsPath: '/app/src/content/releases/tracking-link.png',
      },
    },
  },
]);
const problem = ref<CoreProblemDetails | undefined>(undefined);
const baseTestId = 'nav-bar';

async function getReleases() {
  await viyaMeService.getReleaseItems(undefined, problem, releases);
}

onMounted(async () => {
  await getReleases();
});
</script>
