import { type RouteRecordRaw } from 'vue-router';

import { ROUTES } from './helpers/routes';

const Workflows: Array<RouteRecordRaw> = [
  {
    path: ROUTES.WORKFLOW_LIST.path,
    name: ROUTES.WORKFLOW_LIST.name,
    component: () => import('@/views/workflows/WorkflowOverviewPage.vue'),
    meta: {
      title: ROUTES.WORKFLOW_LIST.title,
      earlyAccessMode: true,
    },
  },
  {
    path: ROUTES.WORKFLOW_CREATE.path,
    name: ROUTES.WORKFLOW_CREATE.name,
    component: () => import('@/views/workflows/WorkflowCreateEditPage.vue'),
    meta: {
      title: ROUTES.WORKFLOW_CREATE.title,
      parents: [ROUTES.WORKFLOW_LIST],
      earlyAccessMode: true,
    },
  },
  {
    path: ROUTES.WORKFLOW_EDIT.path,
    name: ROUTES.WORKFLOW_EDIT.name,
    component: () => import('@/views/workflows/WorkflowCreateEditPage.vue'),
    meta: {
      title: ROUTES.WORKFLOW_EDIT.title,
      parents: [ROUTES.WORKFLOW_LIST],
      earlyAccessMode: true,
    },
  },
];

export default Workflows;
