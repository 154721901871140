<template>
  <PopoverComponent alignment="end" data-testid="user.options">
    <template #content>
      <div class="flex flex-col">
        <ActionListComponent
          @selected="handleSelect"
          :options="options"
          nested
          data-testid="user.options"
        />
        <template v-if="posthog.isFeatureEnabled('dev-mode')">
          <hr />
          <div class="flex items-center justify-between px-3 py-2">
            <span class="flex items-center gap-2">
              <IconComponent name="PhCode" />
              Dev mode
            </span>
            <SwitchComponent v-model="contextStore.devMode" size="sm" />
          </div>
        </template>
      </div>
    </template>
    <ButtonComponent
      variant="ghost"
      :data-testid="baseTestId + '.user.trigger'"
      icon="PhUserCircle"
    />
  </PopoverComponent>
</template>

<script setup lang="ts">
import { Configuration, FrontendApi } from '@ory/client';
import {
  ActionListComponent,
  ButtonComponent,
  IconComponent,
  PopoverComponent,
  SwitchComponent,
} from '@shipitsmarter/viya-ui-warehouse';
import { onMounted, Ref, ref } from 'vue';
import { useRouter } from 'vue-router';

import { usePostHog } from '@/composables/usePosthog';
import { ROUTES } from '@/router/helpers/routes';
import { useContextStore } from '@/store';

const contextStore = useContextStore();
const router = useRouter();
const baseTestId = 'nav-bar';
const logoutUrl: Ref<string> = ref('');

const { posthog } = usePostHog();

const ory = new FrontendApi(
  new Configuration({
    basePath: '/auth/ory',
    baseOptions: {
      withCredentials: true,
    },
  })
);

async function setLogoutUrl() {
  if (import.meta.env.PROD) {
    ory.createBrowserLogoutFlow().then(({ data }) => {
      logoutUrl.value =
        data.logout_url + '&return_to=' + encodeURIComponent(window.location.origin);
    });
  }
}

const logout = () => {
  window.location.href = logoutUrl.value;
};

onMounted(async () => {
  await setLogoutUrl();
});

const handleSelect = (option: Option) => {
  if (option.action) {
    option.action();
  } else {
    router.push({ name: option.route });
  }
};

type Option = {
  text: string;
  icon: string;
  route?: string;
  action?: () => void;
};
const options = [
  {
    text: 'Profile',
    icon: 'PhUserCircle',
    route: ROUTES.USER_PROFILE.name,
  },
  {
    text: 'Logout',
    icon: 'PhSignOut',
    action: logout,
  },
];
</script>
