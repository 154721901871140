import createClient, { Middleware } from 'openapi-fetch';
import { PathsWithMethod } from 'openapi-typescript-helpers';

import { type paths as carrierGatewayPaths } from '@/generated/carrier-gateway';
import { type components } from '@/generated/configs';
import { type paths as configsPaths } from '@/generated/configs';
import { type paths as ftpPaths } from '@/generated/ftp';
import { type paths as printingPaths } from '@/generated/printing';
import { type paths as ratesPaths } from '@/generated/rates';
import { type paths as shippingPaths } from '@/generated/shipping';
import createUntypedClient from '@/services/untypedClient';
import { usePlaygroundStore } from '@/store/playground';

type AddPrefix<SS extends string, K> = K extends string ? `/${SS}${K}` : never;

export enum ClientTypesEnum {
  Configs = 'configs',
  Shipping = 'shipping',
  FtpUploader = 'ftp-uploader',
  CarrierGateway = 'carrier-gateway',
  Ftp = 'ftp',
  Printing = 'printing',
  Rates = 'rates',
}

type ClientTypeKeys = ClientTypesEnum[number];

type TransformKeys<T extends object, SS extends ClientTypeKeys[number]> = {
  [K in keyof T as AddPrefix<SS, K>]: T[K];
};
type MergeTransformedTypes<T extends object> = {
  [K in keyof T]: T[K];
};
type TransformedConfigs = MergeTransformedTypes<
  TransformKeys<configsPaths, ClientTypesEnum.Configs>
>;
type TransformedShipping = MergeTransformedTypes<
  TransformKeys<shippingPaths, ClientTypesEnum.Shipping>
>;
type TransformedFtp = MergeTransformedTypes<TransformKeys<ftpPaths, ClientTypesEnum.Ftp>>;
type TransformedCarrierGateway = MergeTransformedTypes<
  TransformKeys<carrierGatewayPaths, ClientTypesEnum.CarrierGateway>
>;
type TransformedPrinting = MergeTransformedTypes<
  TransformKeys<printingPaths, ClientTypesEnum.Printing>
>;
type TransFormedRates = MergeTransformedTypes<TransformKeys<ratesPaths, ClientTypesEnum.Rates>>;

type UnionToIntersection<U> = (U extends object | undefined ? (k: U) => void : never) extends (
  k: infer I
) => void
  ? I
  : never;
type MergedTransformedTypes = UnionToIntersection<
  | TransformedConfigs
  | TransformedShipping
  | TransformedCarrierGateway
  | TransformedFtp
  | TransformedPrinting
  | TransFormedRates
>;

export type ViyaGetPaths =
  | PathsWithMethod<MergeTransformedTypes<TransformKeys<ratesPaths, ClientTypesEnum.Rates>>, 'get'>
  | PathsWithMethod<
      MergeTransformedTypes<TransformKeys<printingPaths, ClientTypesEnum.Printing>>,
      'get'
    >
  | PathsWithMethod<MergeTransformedTypes<TransformKeys<ftpPaths, ClientTypesEnum.Ftp>>, 'get'>
  | PathsWithMethod<
      MergeTransformedTypes<TransformKeys<carrierGatewayPaths, ClientTypesEnum.CarrierGateway>>,
      'get'
    >
  | PathsWithMethod<
      MergeTransformedTypes<TransformKeys<shippingPaths, ClientTypesEnum.Shipping>>,
      'get'
    >
  | PathsWithMethod<
      MergeTransformedTypes<TransformKeys<configsPaths, ClientTypesEnum.Configs>>,
      'get'
    >;

export type ViyaPostPaths =
  | PathsWithMethod<MergeTransformedTypes<TransformKeys<ratesPaths, ClientTypesEnum.Rates>>, 'post'>
  | PathsWithMethod<
      MergeTransformedTypes<TransformKeys<printingPaths, ClientTypesEnum.Printing>>,
      'post'
    >
  | PathsWithMethod<MergeTransformedTypes<TransformKeys<ftpPaths, ClientTypesEnum.Ftp>>, 'post'>
  | PathsWithMethod<
      MergeTransformedTypes<TransformKeys<carrierGatewayPaths, ClientTypesEnum.CarrierGateway>>,
      'post'
    >
  | PathsWithMethod<
      MergeTransformedTypes<TransformKeys<shippingPaths, ClientTypesEnum.Shipping>>,
      'post'
    >
  | PathsWithMethod<
      MergeTransformedTypes<TransformKeys<configsPaths, ClientTypesEnum.Configs>>,
      'post'
    >;

export type ViyaPutPaths =
  | PathsWithMethod<MergeTransformedTypes<TransformKeys<ratesPaths, ClientTypesEnum.Rates>>, 'put'>
  | PathsWithMethod<
      MergeTransformedTypes<TransformKeys<printingPaths, ClientTypesEnum.Printing>>,
      'put'
    >
  | PathsWithMethod<MergeTransformedTypes<TransformKeys<ftpPaths, ClientTypesEnum.Ftp>>, 'put'>
  | PathsWithMethod<
      MergeTransformedTypes<TransformKeys<carrierGatewayPaths, ClientTypesEnum.CarrierGateway>>,
      'put'
    >
  | PathsWithMethod<
      MergeTransformedTypes<TransformKeys<shippingPaths, ClientTypesEnum.Shipping>>,
      'put'
    >
  | PathsWithMethod<
      MergeTransformedTypes<TransformKeys<configsPaths, ClientTypesEnum.Configs>>,
      'put'
    >;

export type ViyaDeletePaths =
  | PathsWithMethod<
      MergeTransformedTypes<TransformKeys<ratesPaths, ClientTypesEnum.Rates>>,
      'delete'
    >
  | PathsWithMethod<
      MergeTransformedTypes<TransformKeys<printingPaths, ClientTypesEnum.Printing>>,
      'delete'
    >
  | PathsWithMethod<MergeTransformedTypes<TransformKeys<ftpPaths, ClientTypesEnum.Ftp>>, 'delete'>
  | PathsWithMethod<
      MergeTransformedTypes<TransformKeys<carrierGatewayPaths, ClientTypesEnum.CarrierGateway>>,
      'delete'
    >
  | PathsWithMethod<
      MergeTransformedTypes<TransformKeys<shippingPaths, ClientTypesEnum.Shipping>>,
      'delete'
    >
  | PathsWithMethod<
      MergeTransformedTypes<TransformKeys<configsPaths, ClientTypesEnum.Configs>>,
      'delete'
    >;

export type CoreProblemDetails = components['schemas']['CoreProblemDetails'];

const playgroundMiddleware: Middleware = {
  async onRequest({ request }) {
    const playgroundStore = usePlaygroundStore();
    const newUrl = new URL(request.url);
    if (playgroundStore.isPlayground) {
      newUrl.searchParams.set('playground', playgroundStore.isPlayground ? 'true' : 'false');
    } else if (newUrl.searchParams.has('playground')) {
      newUrl.searchParams.delete('playground');
    } else {
      return undefined; // No need to modify the request in this case.
    }

    return new Request(newUrl, request);
  },
};

export const ApiClient = createClient<MergedTransformedTypes>({
  baseUrl: '/api/',
});
ApiClient.use(playgroundMiddleware);

export const untypedClient = createUntypedClient();

export function toProblem(res: FetchErrorResponse): CoreProblemDetails {
  if (res.error && Object.keys(res.error).length > 0) {
    return res.error;
  }

  return {
    title: res.response.statusText,
    status: res.response.status,
    type: `https://httpstatuses.io/${res.response.status}`,
    instance: res.response.url,
    errors: [],
  };
}

export function isOk(res: FetchErrorResponse): boolean {
  return res.response.ok;
}
