import { BaseService, DataOrProblem, Options, Res } from '@/services/base-service';
import { untypedClient } from '@/services/clients';
import { ReleaseItem } from '@/services/untyped/untyped-types';

const parentPath = 'https://viya.me';
export class ViyaMeService extends BaseService {
  public async getReleaseItems(
    ...args: Options<ReleaseItem[]>
  ): Promise<DataOrProblem<ReleaseItem[]>> {
    return await this.executeRequest(
      async () => (await untypedClient.GET(`${parentPath}/releases/api`)) as Res<ReleaseItem[]>,
      ...args
    );
  }
}
