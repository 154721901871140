import { BaseService, DataOrProblem, EmptyResponseOptions, Options } from '@/services/base-service';
import { ApiClient } from '@/services/clients';
import {
  CreateToken,
  GetTokenListQuery,
  Token,
  TokenListWrapper,
} from '@/services/configs/configs-types';

const parentPath = '/configs/v1/tokens';

// redoc at https://stpviyacontracts.z6.web.core.windows.net/?swagger=%252Fconfigs%252Fswagger.json#tag/Authz/paths/~1v1~1tokens/get
// part of `Authz`
export class TokenService extends BaseService {
  public static canCreateSingle = () => this.authPost(parentPath);
  public async createSingle(
    token: CreateToken,
    ...args: Options<Token>
  ): Promise<DataOrProblem<Token>> {
    return await this.executeRequest<Token>(
      async () =>
        await ApiClient.POST(parentPath, {
          body: token,
        }),
      ...args
    );
  }

  public static canReadSingle = () => this.authGet(parentPath);
  public async readSingle(hash: string, ...args: Options<Token>): Promise<DataOrProblem<Token>> {
    return await this.executeRequest<Token>(
      async () =>
        await ApiClient.GET(`${parentPath}/{hash}`, {
          params: {
            path: { hash },
          },
        }),
      ...args
    );
  }

  public static canReadListWithQuery = () => this.authGet(parentPath);
  public async readListWithQuery(
    query: GetTokenListQuery,
    ...args: Options<TokenListWrapper>
  ): Promise<DataOrProblem<TokenListWrapper>> {
    return await this.executeRequest<TokenListWrapper>(
      async () =>
        await ApiClient.GET(parentPath, {
          params: {
            query,
          },
        }),
      ...args
    );
  }

  public static canDeleteSingle = () => this.authDelete(`${parentPath}/{hash}`);
  public async deleteSingle(
    hash: string,
    ...args: EmptyResponseOptions
  ): Promise<DataOrProblem<never>> {
    return await this.executeRequest<never>(
      async () =>
        await ApiClient.DELETE(`${parentPath}/{hash}`, {
          params: {
            path: { hash },
          },
        }),
      ...args
    );
  }
}
