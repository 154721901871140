import { type RouteRecordRaw } from 'vue-router';

import { type AccessMeta } from '@/services/auth/base-auth-service';
import { useCarrierProfileStore } from '@/store/carrier-profile';

import { ROUTES } from './helpers/routes';

const CarrierConfiguration: Array<RouteRecordRaw> = [
  {
    path: ROUTES.CARRIER_PROFILE_CREATE.path,
    name: ROUTES.CARRIER_PROFILE_CREATE.name,
    component: () => import('@/views/configuration/CarrierProfileCreatePage.vue'),
    meta: {
      title: ROUTES.CARRIER_PROFILE_CREATE.title,
      parents: { 0: ROUTES.CARRIER_PROFILE_LIST },
      access: {
        url: 'locations/:location/carrierProfiles',
        method: 'POST',
        service: 'configs',
        version: 'v1',
      } as AccessMeta,
    },
  },

  {
    path: ROUTES.CARRIER_LOCATION_LIST.path,
    name: ROUTES.CARRIER_LOCATION_LIST.name,
    component: () => import('@/views/configuration/LocationSelectionPage.vue'),
    meta: {
      title: ROUTES.CARRIER_LOCATION_LIST.title,
    },
  },
  {
    path: ROUTES.CARRIER_PROFILE_LIST.path,
    name: ROUTES.CARRIER_PROFILE_LIST.name,
    props: true,
    component: () => import('@/views/configuration/CarrierProfileOverviewPage.vue'),
    meta: {
      title: ROUTES.CARRIER_PROFILE_LIST.title,
    },
    beforeEnter: (to, from, next) => {
      const store = useCarrierProfileStore();
      store.$reset();
      next();
    },
  },
  {
    path: ROUTES.CARRIER_PROFILE_EDIT.path,
    name: ROUTES.CARRIER_PROFILE_EDIT.name,
    props: true,
    component: () => import('@/views/configuration/CarrierProfileCreatePage.vue'),
    meta: {
      title: ROUTES.CARRIER_PROFILE_EDIT.title,
      parents: { 0: ROUTES.CARRIER_PROFILE_LIST },
    },
  },
  {
    path: ROUTES.CARRIER_PROFILE_ONBOARDING.path,
    name: ROUTES.CARRIER_PROFILE_ONBOARDING.name,
    props: true,
    component: () => import('@/views/configuration/CarrierOnboardingPage.vue'),
    meta: {
      title: ROUTES.CARRIER_PROFILE_ONBOARDING.title,
      parents: { 0: ROUTES.CARRIER_PROFILE_LIST },
    },
  },
  {
    path: ROUTES.CARRIER_PROFILE_INTEGRATION_EDIT.path,
    name: ROUTES.CARRIER_PROFILE_INTEGRATION_EDIT.name,
    props: true,
    component: () => import('@/views/configuration/CarrierIntegrationEditPage.vue'),
    meta: {
      title: ROUTES.CARRIER_PROFILE_INTEGRATION_EDIT.title,
      parents: { 0: ROUTES.CARRIER_PROFILE_LIST },
    },
  },
];

export default CarrierConfiguration;
