import mixpanel from 'mixpanel-browser';

const MODE = ['localhost', 'pr-', 'present', 'e2e'].some((str) =>
  window.location.href.includes(str)
)
  ? 'development'
  : 'production';

const MP_PROJECT_ID = {
  development: '1e3d909bd31ec82bf0a524d7c2dc1739',
  production: 'c4f861139822576486123631164ba5e3',
};

const MP_CONFIG = {
  development: {
    // debug: true,
    track_pageview: false,
    record_sessions_percent: 1, // set too 100% if you need to test
  },
  production: {
    track_pageview: 'url-with-path-and-query-string',
    api_host: '/mpproxy',
    record_sessions_percent: 100,
  },
};

export default {
  install(app) {
    app.config.globalProperties.$mixpanel = mixpanel.init(MP_PROJECT_ID[MODE], {
      ...MP_CONFIG[MODE],
      persistence: 'localStorage',
      record_min_ms: 8,
      record_collect_fonts: true,
      loaded: function (mixpanel) {
        if (MODE === 'development') {
          mixpanel.opt_out_tracking();
          // mixpanel.opt_in_tracking();
        }
      },
    });
  },
};
