import { BaseService, DataOrProblem, Options, Res } from '@/services/base-service';
import createUntypedClient from '@/services/untypedClient';

export type UserRoles = {
  decision_id: string;
  result: {
    roles: string[];
  };
};

const parentPath = '/api/opa/v1';
const opaClient = createUntypedClient({ baseUrl: parentPath });
export class OpaService extends BaseService {
  public async getRoles(...args: Options<UserRoles>): Promise<DataOrProblem<UserRoles>> {
    return await this.executeRequest<UserRoles>(
      async () => (await opaClient.GET('/data/get_role_keys')) as Res<UserRoles>,
      ...args
    );
  }
}
