<template>
  <TooltipComponent
    v-if="isVisible"
    :content="props.label"
    direction="right"
    :hidden="showLabel"
    :delay="700"
  >
    <RouterLink
      @click="handleClick"
      :style="index ? { transitionDelay: index * 30 + 'ms' } : ''"
      :to="props.routeLink"
      active-class="!text-accent bg-gray-300"
      v-bind="$attrs"
      :class="{ 'inline-block': !showLabel, '': configMenu }"
      class="flex items-center gap-3 rounded-lg px-3 py-3 text-muted hover:bg-gray-300 hover:text-accent hover:no-underline"
    >
      <IconComponent :name="props.icon" size="xl" />
      <span v-if="showLabel" class="flex grow items-center justify-between font-semibold">
        {{ props.label }}
        <span v-if="betaMode" class="rounded-xl bg-info-emphasis p-1 px-2 text-xs text-white">
          BETA
        </span>
      </span>
    </RouterLink>
  </TooltipComponent>
</template>
<script setup lang="ts">
import { IconComponent, IconName, TooltipComponent } from '@shipitsmarter/viya-ui-warehouse';
import { computed } from 'vue';

import { useContextStore } from '@/store';
import { sendTrackingEvent } from '@/utils/logger';

const emit = defineEmits(['router-click']);

const handleClick = () => {
  sendTrackingEvent('navigation.item.clicked', {
    route: props.routeLink.name,
    source: 'sidebar',
    is_sidebar_open: props.showLabel, // label is hidden when sidebar is open
  });
  emit('router-click');
};
const props = defineProps<{
  routeLink: { name: string; params?: Record<string, string | number> };
  icon: IconName;
  label: string;
  showLabel?: boolean;
  devMode?: boolean;
  betaMode?: boolean;
  index?: number;
  class?: string;
  configMenu?: boolean;
  hidden?: boolean;
}>();

const store = useContextStore();
const devModeOn = store.getDevMode;
const isVisible = computed(() => ((props.devMode && devModeOn) || !props.devMode) && !props.hidden);
</script>
