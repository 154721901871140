import { BaseService, DataOrProblem, Options, Res } from '@/services/base-service';
import { untypedClient } from '@/services/clients';

export interface UserInfo {
  identity: {
    id: string;
    traits: {
      email: string;
      name: string;
    };
    organization_id?: string | null; // test
  };
}

export class AuthService extends BaseService {
  public static canPostConfigs = () => this.authPostUntyped('/configs/v1/');
  public static canGetInternal = () => this.authGetUntyped('/internal');
  public static canAllowed = () => this.pureAuthUntyped('/allowed');
  public static canForbidden = () => this.pureAuthUntyped('/forbidden');
  public static canOpaAlmostThere = () => this.authGetUntyped('/opa/almost-there');

  public async getUserInfo(...args: Options<UserInfo>): Promise<DataOrProblem<UserInfo>> {
    return await this.executeRequest<UserInfo>(
      async () => (await untypedClient.GET('/auth/userinfo')) as Res<UserInfo>,
      ...args
    );
  }
}
