import { ViyaDeletePaths, ViyaGetPaths, ViyaPostPaths, ViyaPutPaths } from '@/services/clients';
import createUntypedClient from '@/services/untypedClient';

export type HttpMethod = 'POST' | 'GET' | 'PATCH' | 'PUT' | 'DELETE';

export type AccessMeta = {
  method: HttpMethod;
  service: string;
  url: string;
  version: string;
};

export type AuthResult = {
  authorized: boolean;
  method?: HttpMethod;
  url: string;
  fullAuthUrl: string;
};
const authDecisionParentUrl = '/auth/decisions';
const authDecisionsClient = createUntypedClient({ baseUrl: authDecisionParentUrl });

// NOTE: this service calls authoriZation endpoints (not autheNtication endpoints)
export abstract class BaseAuthService {
  // auth typed
  protected static authGet = (path: ViyaGetPaths) => this.authDecision('GET', path);
  protected static authPost = (path: ViyaPostPaths) => this.authDecision('POST', path);
  protected static authPut = (path: ViyaPutPaths) => this.authDecision('PUT', path);
  protected static authDelete = (path: ViyaDeletePaths) => this.authDecision('DELETE', path);

  private static async authDecision(method: HttpMethod, allowedPath: string): Promise<AuthResult> {
    const authPath = `/${method}/api${allowedPath}`;
    const res = await this.pureAuthUntyped(authPath);
    return {
      authorized: res.authorized,
      method: res.method,
      url: allowedPath,
      fullAuthUrl: res.fullAuthUrl,
    };
  }

  // auth untyped
  protected static authGetUntyped = (path: string) => this.authDecision('GET', path);
  protected static authPostUntyped = (path: string) => this.authDecision('POST', path);
  protected static authPutUntyped = (path: string) => this.authDecision('PUT', path);
  protected static authDeleteUntyped = (path: string) => this.authDecision('DELETE', path);
  protected static pureAuthUntyped = (path: string) => this.pureAuthDecision(path);

  private static async pureAuthDecision(allowedPath: string): Promise<AuthResult> {
    const res = await authDecisionsClient.GET(allowedPath);
    return {
      authorized: res.response.ok,
      url: allowedPath,
      fullAuthUrl: `${authDecisionParentUrl}${allowedPath}`,
    };
  }

  // by AccessMeta
  public static async authByAccessMeta(access: AccessMeta): Promise<AuthResult> {
    const allowedPath = `/${access.service}/${access.version}/${access.url}`;
    const url = `/${access.method}/api${allowedPath}`;
    let authorized: boolean;
    try {
      const res = await authDecisionsClient.GET(url);
      authorized = res.response.ok;
    } catch (error) {
      console.log('Decision check failed', error);
      authorized = false;
    }

    return {
      authorized,
      method: access.method,
      url: allowedPath,
      fullAuthUrl: `${authDecisionParentUrl}${url}`,
    };
  }
}
