import { BaseService, DataOrProblem, EmptyResponseOptions, Options } from '@/services/base-service';
import { ApiClient } from '@/services/clients';
import {
  DeleteLocationQuery,
  GetLocationListQuery,
  Location,
  LocationListWrapper,
  LocationSummary,
  LocationWithReference,
} from '@/services/configs/configs-types';

const configsBasePath = '/configs/v1';
const parentPath = `${configsBasePath}/locations`;

// redoc at https://stpviyacontracts.z6.web.core.windows.net/?swagger=%252Fconfigs%252Fswagger.json#tag/Location
export class ConfigsLocationService extends BaseService {
  public static canCreateSingle = () => BaseService.authPost(parentPath);
  public async createSingle(
    body: LocationWithReference,
    ...args: EmptyResponseOptions
  ): Promise<DataOrProblem<never>> {
    return await this.executeRequest<never>(
      async () => await ApiClient.POST(parentPath, { body }),
      ...args
    );
  }

  public async readSingle(
    reference: string,
    ...args: Options<LocationWithReference>
  ): Promise<DataOrProblem<LocationWithReference>> {
    return await this.executeRequest<LocationWithReference>(
      async () =>
        await ApiClient.GET(`${parentPath}/{reference}`, {
          params: {
            path: { reference },
          },
        }),
      ...args
    );
  }

  public static canReadListWithQuery = () => BaseService.authGet(parentPath);
  public async readListWithQuery(
    query: GetLocationListQuery = undefined,
    ...args: Options<LocationListWrapper>
  ): Promise<DataOrProblem<LocationListWrapper>> {
    return await this.executeRequest<LocationListWrapper>(
      async () =>
        await ApiClient.GET(parentPath, {
          query: { ...query },
        }),
      ...args
    );
  }

  public static canUpdateSingle = () => BaseService.authPut(`${parentPath}/{reference}`);
  public async updateSingle(
    reference: string,
    body: Location,
    ...args: EmptyResponseOptions
  ): Promise<DataOrProblem<never>> {
    return await this.executeRequest<never>(
      async () =>
        await ApiClient.PUT(`${parentPath}/{reference}`, {
          params: {
            path: { reference },
          },
          body,
        }),
      ...args
    );
  }

  public static canDeleteSingle = () => BaseService.authDelete(`${parentPath}/{reference}`);
  public async deleteSingle(
    reference: string,
    query: DeleteLocationQuery = undefined,
    ...args: EmptyResponseOptions
  ): Promise<DataOrProblem<never>> {
    return await this.executeRequest<never>(
      async () =>
        await ApiClient.DELETE(`${parentPath}/{reference}`, {
          params: {
            path: { reference },
            query,
          },
        }),
      ...args
    );
  }

  public async readAllSummaries(
    ...args: Options<LocationSummary[]>
  ): Promise<DataOrProblem<LocationSummary[]>> {
    return await this.executeRequest<LocationSummary[]>(
      async () => await ApiClient.GET(`${configsBasePath}/locationSummaries`),
      ...args
    );
  }

  public static canRenameSingle = () =>
    BaseService.authPut(`${parentPath}/{reference}/rename/{newReference}`);
  public async renameSingle(
    reference: string,
    newReference: string,
    ...args: EmptyResponseOptions
  ): Promise<DataOrProblem<never>> {
    return await this.executeRequest<never>(
      async () =>
        await ApiClient.PUT(`${parentPath}/{reference}/rename/{newReference}`, {
          params: {
            path: { reference, newReference },
          },
        }),
      ...args
    );
  }
}
